import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import MaxWidthWrapper from 'components/layout/wrappers/maxWidthWrapper';
import Skill from 'components/home/skills/skill';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HeaderSkills: React.FC<Props> = (props) => {

  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('skills', {scroll: false, changeRouterState: false})
    }
  }, [props.sectionInView]);

  return (
    <StyledHeaderSkills id="homeSection-skills" sectionInView={props.sectionInView} className="headerSkills">

      <MaxWidthWrapper className="headerSkills-inner">

        <div className="headerSkills-title">
          <h2>My Skills</h2>
        </div>

        <div className="headerSkills-intro">
          These are the technologies I use for most of my projects and the ones I am most comfortable with. The bar represents my level of experience with each one.
        </div>

        <div className="headerSkills-skillGrid">
          <Skill name="React" percent={95} inView={props.sectionInView} color={color.cyan(60)} order={1} iconUrl="/images/icons/icon-react-white.svg" />
          <Skill name="Next JS" percent={95} inView={props.sectionInView} color={color.cyan(60)} order={2} iconUrl="/images/icons/icon-nextjs-white.svg" />
          <Skill name="Typescript" percent={85} inView={props.sectionInView} color={color.cyan(60)} order={3} iconUrl="/images/icons/icon-typescript-white.svg" />
          <Skill name="Node JS" percent={85} inView={props.sectionInView} color={color.cyan(60)} order={4} iconUrl="/images/icons/icon-node-white.svg" />
          <Skill name="Postgres" percent={80} inView={props.sectionInView} color={color.cyan(60)} order={5} iconUrl="/images/icons/icon-postgres-white.svg" />
          <Skill name="Sql Server" percent={75} inView={props.sectionInView} color={color.cyan(60)} order={6} iconUrl="/images/icons/icon-sqlserver-white.svg" />
          <Skill name="Styled Components" percent={90} inView={props.sectionInView} color={color.cyan(60)} order={7} iconUrl="/images/icons/icon-styled-components-white.svg" />
          <Skill name="Figma" percent={80} inView={props.sectionInView} color={color.cyan(60)} order={8} iconUrl="/images/icons/icon-figma-white.svg" />
        </div>

        <div className="headerSkills-block2">
      
          <div className="headerSkills-intro">
            I also usually use some combination of these technologies and services in my projects.
          </div>

          <div className="headerSkills-skillGrid">
            <Skill name="Vercel" percent={85} inView={props.sectionInView} color={color.pink(60)} order={9} iconUrl="/images/icons/icon-vercel-white.svg" />
            <Skill name="TRPC" percent={90} inView={props.sectionInView} color={color.pink(60)} order={10} iconUrl="/images/icons/icon-trpc-white.svg" />
            <Skill name="Prisma" percent={85} inView={props.sectionInView} color={color.pink(60)} order={11} iconUrl="/images/icons/icon-prisma-white.svg" />
            <Skill name="Adobe CC" percent={85} inView={props.sectionInView} color={color.pink(60)} order={12} iconUrl="/images/icons/icon-adobe-cc-white.svg" />
            <Skill name="MS Azure" percent={80} inView={props.sectionInView} color={color.pink(60)} order={13} iconUrl="/images/icons/icon-azure-white.svg" />
            <Skill name="Github" percent={90} inView={props.sectionInView} color={color.pink(60)} order={14} iconUrl="/images/icons/icon-github-white.svg" />
            <Skill name="Storyblok" percent={75} inView={props.sectionInView} color={color.pink(60)} order={15} iconUrl="/images/icons/icon-storyblok-white.svg" />
          </div>

        </div>

      </MaxWidthWrapper>      

    </StyledHeaderSkills>
  );

};

export default HeaderSkills;



const StyledHeaderSkills = styled.div<{sectionInView: boolean}>`

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: calc(var(--header-height) + 2rem) 1.5rem calc(var(--header-height) + 2rem) 1.5rem;
  min-height: 100vh;
  min-height: 100svh;
  background-color: ${color.mauve(20)};

  .headerSkills-inner{
    width: 100%;
    max-width: 60rem;
  }

  .headerSkills-title{

    margin-bottom: 1rem;

    h2{
      font-size: 2rem;
      font-weight: 300;
    }

  }

  .headerSkills-intro{
    opacity: 0.66;
  }

  .headerSkills-skillGrid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(4.5rem, 1fr));
    gap: 1rem;
    margin-top: 2rem;
  }

  .headerSkills-block2{
    margin-top: 3rem;
  }

  @media (min-width: ${bp(3)}) {

    .headerSkills-skillGrid{
      grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
    }

    .headerSkills-block2{
      margin-top: 3rem;
    }

  } 

  @media (min-width: ${bp(5)}) {

    .headerSkills-inner{
      width: 100%;
      max-width: 70rem;
    }

    .headerSkills-title{

      margin-bottom: 1.5rem;

      h2{
        font-size: 2.5rem;
        font-weight: 400;
      }

    }

    .headerSkills-intro{
      font-size: 1.125rem;
      line-height: 1.4;
      letter-spacing: 0;
    }

    .headerSkills-skillGrid{
      grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
      gap: 1.5rem;
    }

    .headerSkills-block2{
      margin-top: 5rem;
    }

  }

`;
