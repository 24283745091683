import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Progress from 'components/home/skills/progress';
import theme, {bp, color} from 'settings/theme';


type Props = {
  name: string,
  percent: number,
  color?: string,
  iconUrl: string,
  order?: number,
  inView?: boolean
};

// Component
const Skill: React.FC<Props> = (props) => {

  return (
    <StyledSkill className="skill">

      <div className="skill-main">

        <div className="skill-progress">
          <Progress inView={props.inView} rating={props.percent} strokeColor={props.color ?? color.pink(65)} delay={((props.order ?? 1) - 1) / 30} />
        </div>

        <div className="skill-logo">
          <img src={props.iconUrl} width="100%" height="100%" alt={props.name} />
        </div>

      </div>
      <div className="skill-name">
        {props.name}
      </div>

    </StyledSkill>
  );
};

export default Skill;


const StyledSkill = styled.div<{}>`

  display: block;

  .skill-main{
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
  }

  .skill-progress{
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .skill-logo{
    z-index: 3;
    width: 40%;
    height: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }

  .skill-name{
    width: 100%;
    margin-top: 0.75rem;
    font-size: 0.875rem;
    text-align: center;
  }

  @media (min-width: ${bp(5)}) {

    .skill-name{
      margin-top: 0.75rem;
      font-size: 1rem;
    }

  } 

`;