import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeAbout: React.FC<Props> = (props) => {

  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('about', {scroll: false, changeRouterState: false});
    }
  }, [props.sectionInView]);

  // Animation
  const container = {
    show: {
      transition: {
        staggerChildren: 0.08
      }
    }
  }

  const children = {
    hidden: { y: 40, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.35
      }
    }
  }


  return (
    <StyledHomeAbout id="homeSection-about" sectionInView={props.sectionInView} className="homeAbout">

      <div className="homeAbout-inner">

        <motion.div
          variants={container}
          initial="hidden"
          animate={props.sectionInView ? "show" : "hidden"}
          className="homeAbout-content"
        >

          <motion.div variants={children} className="homeAbout-title">
            <h2>About Me</h2>
          </motion.div>

          <motion.div variants={children} className="homeAbout-text">
            I&apos;m a full stack developer whose been passionate about the web since designing my first website for my university radio station over 25 years ago. Since then I&apos;ve worked in a number of roles: I&apos;ve run my own digital agency, consulted for FTSE 100 companies and worked as a remote freelancer.
          </motion.div>

          <motion.div variants={children} className="homeAbout-text">
            My journey started with basic HTML websites (before CSS existed), with simple backends built with Cold Fusion and MySQL, hosting sites on my own servers. I worked for a couple of agencies on projects for the likes of Cadburys and Virgin before starting my own small agency in 2004.
          </motion.div>

          <motion.div variants={children} className="homeAbout-text">
            Over the next decade I expanded the agency into a 12 person team, working for clients such as Tesco and Hotel Chocolat, and winning several awards including &quot;Property website of the year&quot; at the UK property awards. Most of our work was based on Microsoft technologies - ASP.net and SQL Server but I found myself spending more time managing than coding... Time for a change!
          </motion.div>

          <motion.div variants={children} className="homeAbout-text">
            I found myself moving towards being a full time JavaScript developer, starting with Angular JS and moving on to React around 5 years ago. I now work exclusively as a full stack JavaScript developer and my preferred stack is now React, NextJS, Postgres and Styled Components, but I love learning new technologies so that probably won&apos;t be true for long...
          </motion.div>

          <motion.div variants={children} className="homeAbout-text">
            <button className="homeButton homeAbout-next" onClick={() => layout.setActiveTab('skills')}>
              <span>Next: My Skills</span>
              <span>
                <img src="/images/icons/icon-arrow-right-white.svg" alt="Next: My Skills" />
              </span>
            </button>
          </motion.div>

        </motion.div>

      </div>      

    </StyledHomeAbout>
  );

};

export default HomeAbout;



const StyledHomeAbout = styled.div<{sectionInView: boolean, isSubmitting: boolean}>`

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: calc(var(--header-height) + 2rem);
  padding-bottom: calc(var(--header-height) + 2rem);
  min-height: 100vh;
  min-height: 100svh;

  .homeAbout-inner{
    width: 100%;
    max-width: 60rem;
  }

  .homeAbout-title{

    margin-bottom: 0.75rem;

    h2{
      font-size: 2rem;
      font-weight: 300;
    }

  }

  .homeAbout-text{

    margin-bottom: 2rem;
    color: ${color.mauve(85)};

    a{
      color: ${color.mauve(100)};
      font-weight: 500;
      text-decoration: underline;
    }

  }



  @media (min-width: ${bp(3)}) {


  } 

  @media (min-width: ${bp(5)}) {

    .homeAbout-title{

      margin-bottom: 1.5rem;

      h2{
        font-size: 2.5rem;
        font-weight: 400;
      }

    }

    .homeAbout-text{
      font-size: 1.125rem;
      line-height: 1.4;
      letter-spacing: 0;
    }

  }

`;
