import React, { } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';
import MaxWidthWrapper from 'components/layout/wrappers/maxWidthWrapper';
import HeaderMainMenu from 'components/layout/header/headerMainMenu';
import HeaderCtaButtons from 'components/layout/header/headerCtaButtons';
import HeaderSocials from 'components/layout/header/headerSocials';
import HeaderMobMenu from 'components/layout/header/headerMobMenu';

import theme, {bp, color} from 'settings/theme';

const Header: React.FC<{}> = (props) => {

  const layout = useLayout();

  return (
    <StyledHeader className="header">

      {/* Fake header for fixed positioning */}
      <div className="header-fake" />

      {/* Fixed header */}
      <div className="header-float">

        {/* Top Line */}
        <div className="header-topLine"></div>


        <MaxWidthWrapper className="header-wrapper">

          
          <div className="header-left">

            {/* Logo */}
            <div className="header-logo">
              <button onClick={() => layout.setActiveTab('intro')}>
                <img src="/images/logo.svg" alt="Logo" />
              </button>
            </div>

            {/* Main Menu */}
            <div className="header-mainMenu">
              <HeaderMainMenu />
            </div>

          </div>

          <div className="header-right">

            {/* CTA Buttons */}
            <div className="header-cta">
              <HeaderCtaButtons />
            </div>

            {/* Mobile Menu */}
            <div className="header-mobMenu">
              <HeaderMobMenu />
            </div>
            
            {/* Social Buttons */}
            <div className="header-socialButtons">
              <HeaderSocials />
            </div>

          </div>
        </MaxWidthWrapper>
      </div>

    </StyledHeader>
  );

};

export default Header;


// Header
const StyledHeader = styled.header`

  /* .header-fake{
    height: var(--header-height);
  } */

  .header-float{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: var(--header-height);
    background-color: rgba(78, 90, 132, 0.8);
    backdrop-filter: blur(5px);
  }

  .header-topLine{
    width: 100%;
    height: 0.375rem;
    background-color: ${color.cyan(65)};
  }

  .header-wrapper{
    display: flex;
    align-items: center;
    height: calc(var(--header-height) - 0.375rem);
  }

  .header-left {

    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-left: 1rem;

    .header-logo{

      img{
        width: auto;
        height: 2rem;
      }

    }

    .header-mainMenu{
      display: none;
    }
    
  }

  

  .header-right{

    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;

    .header-cta{
      display: none;
    }

    .header-mobMenu{
      margin-right: -0.5rem;
    }

    .header-socialButtons{
      margin-left: 1rem;
    }

  }

  @media (min-width: ${bp(3)}) {

    .header-left {
      padding-left: 1.5rem;
    }

    .header-right {

      padding-right: 1.5rem;

      .header-socialButtons{
        margin-left: 1.5rem;
      }

    }

  }

  @media (min-width: ${bp(4)}) {
/* 
    .header-fake{
      height: 5rem;
    } */

    .header-float{
      height: 5rem;
    }

    .header-left{

      .header-mainMenu{
        display: flex;
        margin-left: 3rem;
      }

    }

    .header-right {

      .header-mobMenu{
        display: none;  
      }

    }

  }


  @media (min-width: ${bp(5)}) {

    /* .header-fake{
      height: 6rem;
    } */

    .header-float{
      height: 6rem;
    }

    .header-left {

      padding-left: 0;


      .header-logo{

        img{
          height: 2.4rem;
        }

      }
      
    }

    .header-right {

      margin-right: 0;

      .header-cta{
        display: flex;  
        margin-left: 2rem;
      }

    }

  }


`