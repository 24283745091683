import React from 'react';
import styled from 'styled-components';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';

import theme, {bp, color} from 'settings/theme';

type Props = {
  inputType?: string,
  label: string,
  name: string,
  placeholder?: string,
  register: UseFormRegister<any>,
  options?: RegisterOptions,
  isError?: boolean
};

// Component
const FormInput: React.FC<Props> = (props) => {

  const inputType = props?.inputType || 'input';

  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <StyledFormInput isFocused={isFocused} isError={props.isError} className="formInput">

      <div className="formInput-main">

        <label className="formInput-label">
            
          <div className="formInput-title">
            {props.label}
          </div>

          {inputType === 'input' &&
            <input
              {...props.register(props.name, props.options)}
              className="formInput-input"
              name={props.name}
              placeholder={props.placeholder}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          }

          {inputType === 'textarea' &&
            <textarea
              {...props.register(props.name, props.options)}
              className="formInput-textarea"
              name={props.name}
              placeholder={props.placeholder}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          }

        </label>

      </div>

      <div className="formInput-highlight"></div>

    </StyledFormInput>
  );
};

export default FormInput;

const StyledFormInput = styled.div<{isFocused: boolean, isError: boolean}>`

  position: relative;
  display: flex;
  flex-direction: column;
  

  .formInput-main{
    z-index: 2;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: ${props => props.isError ? '#953d57' : color.mauve(24)};
    transition: background-color 0.15s ease-in-out;
  }

  .formInput-highlight{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.6rem;
    background-color: ${color.pink(60)};
    transform: ${props => props.isFocused ? 'translateX(-0.25rem)' : 'none'};
    transition: transform 0.2s ease-in-out;
  }

  .formInput-label {
    font-size: 0.925rem;
    font-weight: 350;
  }

  .formInput-title{
    margin-bottom: 0.25rem;
    opacity: 0.8;
  }

  .formInput-input, .formInput-textarea{

    width: 100%;
    margin-left: -0.5rem;
    margin-bottom: -0.5rem;
    padding: 0 0.5rem;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    outline: none;
    background-color: transparent;

    &::placeholder {
      font-weight: bold;
      opacity: 0.4;
      color: white;
      font-weight: 400;
    }

  }

  .formInput-input{
    height: 2.5rem;
  }

  .formInput-textarea{
    height: 6rem;
  }

`;