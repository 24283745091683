import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const QAndA: React.FC<Props> = (props) => {

  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('q-and-a', {scroll: false, changeRouterState: false});
    }
  }, [props.sectionInView]);

  // Animation
  const container = {
    show: {
      transition: {
        staggerChildren: 0.08
      }
    }
  }

  const children = {
    hidden: { y: 40, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.35
      }
    }
  }


  return (
    <StyledQAndA id="homeSection-q-and-a" sectionInView={props.sectionInView} className="qAndA">

      <div className="qAndA-inner">

        <motion.div
          variants={container}
          initial="hidden"
          animate={props.sectionInView ? "show" : "hidden"}
          className="qAndA-content"
        >

          <motion.div variants={children} className="qAndA-title">
            <h2>Q &amp; A</h2>
          </motion.div>

          <motion.div variants={children} className="qAndA-intro">
            A few of the questions that recruiters ask most often. Of course, if you have more then please <a onClick={() => layout.setActiveTab('contact')}>contact me</a>.
          </motion.div>

          <motion.div variants={children} className="qAndA-block">
            <div className="qAndA-q">
                Q. What kind of work are you looking for?
            </div>
            <div className="qAndA-a">
                A. I&apos;m currently looking for contract work but would consider the right full-time position. I&apos;d prefer to work with the technologies <a onClick={() => layout.setActiveTab('skills')}>I&apos;m currently proficient in</a> but always eager to learn new languages and products. I&apos;m currently only looking for remote work. I&apos;m based in the UK so happy to work European hours but I&apos;ve recently been working on contracts for a US company, working US hours and I&apos;m happy to continue doing that.
            </div>
          </motion.div>

          <motion.div variants={children} className="qAndA-block">
            <div className="qAndA-q">
              Q. Do you prefer frontend or backend?
            </div>
            <div className="qAndA-a">
              A. I enjoy both, but if I had to make a choice I&apos;d say that I lean slightly towards frontend. I think I just prefer working on the part that that people see and interact with. I particularly enjoy creating intuitive and simple interfaces for complicated products. That said, I&apos;m totally happy buried in SQL all day.
            </div>
          </motion.div>

          <motion.div variants={children} className="qAndA-block">
            <div className="qAndA-q">
              Q.  On your own or in a team?
            </div>
            <div className="qAndA-a">
              A. I think a good developer has to be good at both, but like many developers I think I&apos;m most productive when left to focus on the job at hand. My ideal project starts with comprehensive planning and good team communication, followed by sessions of focussed solo work and I find that working remotely helps to achieve this with few interruptions.
            </div>
          </motion.div>

          <motion.div variants={children} className="qAndA-block">
            <div className="qAndA-q">
              Q. Which part of your job do you enjoy most?
            </div>
            <div className="qAndA-a">
              A. I love the fact that the technologies we work with are constantly changing and improving. I&apos;ve watched the progress from slow loading text-based websites to full-on SaaS products - single page applications with load times measured in milliseconds. As a developer we&apos;re constantly having to level up and learn new tech and I love the fact that each project I work on is (generally) faster and better than the previous one. I&apos;m always looking forward to seeing what&apos;s next on the horizon.
            </div>
          </motion.div>

          <motion.div variants={children} className="qAndA-block">
            <div className="qAndA-q">
              Q. So what IS coming next then?
            </div>
            <div className="qAndA-a">
              A. There is a lot of talk right now about AI taking developers&apos; jobs, but the history of web development is littered with new technologies that make things easier for us, and yet there are more developers making more things which are more complicated than ever. AI will be a huge boon to our industry as one developer will be able to build products which would have taken a whole team to build. I&apos;m looking forward to seeing how AI can supercharge the work we do and take the web to the next level.
            </div>
          </motion.div>

          <motion.div variants={children} className="qAndA-block">
            <button className="homeButton qAndA-next" onClick={() => layout.setActiveTab('contact')}>
              <span>Next: Hire Me!</span>
              <span>
                <img src="/images/icons/icon-arrow-right-white.svg" alt="Next: Hire Me" />
              </span>
            </button>
          </motion.div>

        </motion.div>

      </div>      

    </StyledQAndA>
  );

};

export default QAndA;



const StyledQAndA = styled.div<{sectionInView: boolean, isSubmitting: boolean}>`

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: calc(var(--header-height) + 2rem);
  padding-bottom: calc(var(--header-height) + 2rem);
  min-height: 100vh;
  min-height: 100svh;

  .qAndA-inner{
    width: 100%;
    max-width: 60rem;
  }

  .qAndA-title{

    margin-bottom: 0.75rem;

    h2{
      font-size: 2rem;
      font-weight: 300;
    }

  }

  .qAndA-intro{

    margin-bottom: 2rem;
    color: ${color.mauve(85)};

    a{
      color: ${color.mauve(100)};
      font-weight: 500;
      text-decoration: underline;
    }


  }

  .qAndA-block{

    margin-bottom: 2rem;

    a{
      color: ${color.mauve(100)};
      font-weight: 500;
      text-decoration: underline;
    }

    .qAndA-q{
      margin-bottom: 0.5rem;
      font-size: 1.125rem;
      font-weight: 500;
    }

    .qAndA-a{
      color: ${color.mauve(85)};
      font-size: 0.925rem;
    }

  }



  @media (min-width: ${bp(3)}) {


  } 

  @media (min-width: ${bp(5)}) {

    .qAndA-title{

      margin-bottom: 1.5rem;

      h2{
        font-size: 2.5rem;
        font-weight: 400;
      }

    }

    .qAndA-intro{
      font-size: 1.125rem;
      line-height: 1.4;
      letter-spacing: 0;
    }

    .qAndA-block{
      font-size: 1.125rem;
      line-height: 1.4;
      letter-spacing: 0;
    }

  }

`;
