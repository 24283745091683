import React, { useMemo, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {};

// Component
const HeaderMainMenu: React.FC<Props> = (props) => {

  const layout = useLayout();
  const router = useRouter();

  const activeTab = useMemo(() => {

    const homePageTabs = ['/', '/about', '/skills', '/case-studies', '/q-and-a', '/contact'];

    if(homePageTabs.includes(router.asPath)){
      return layout.activeTab === '/' ? 'intro' : layout.activeTab;
    }

  }, [layout.activeTab, router.asPath]);

  return (
    <StyledHeaderMainMenu className="headerMainMenu">

      <ul className="headerMainMenu-menu">
        <li className={activeTab === 'intro' ? 'is-active' : ''} onClick={() => layout.setActiveTab('intro')}>
          <Link shallow={true} href="/" className="headerMainMenu-link">
            Intro
          </Link>
        </li>
        <li className={activeTab === 'about' ? 'is-active' : ''} onClick={() => layout.setActiveTab('about')}>
          <Link shallow={true} href="/about" className="headerMainMenu-link">
            About Me
          </Link>
        </li>
        <li className={activeTab === 'skills' ? 'is-active' : ''} onClick={() => layout.setActiveTab('skills')}>
          <Link shallow={true} href="/skills" className="headerMainMenu-link">
            My Skills
          </Link>
        </li>
        <li className={activeTab === 'case-studies' ? 'is-active' : ''} onClick={() => layout.setActiveTab('case-studies')}>
          <Link shallow={true} href="/case-studies" className="headerMainMenu-link">
            Case Studies
          </Link>
        </li>
        <li className={activeTab === 'q-and-a' ? 'is-active' : ''} onClick={() => layout.setActiveTab('q-and-a')}>
          <Link shallow={true} href="/q-and-a" className="headerMainMenu-link">
            Q &amp; A
          </Link>
        </li>
        <li className={`headerMainMenu-menu--contact ${activeTab === 'contact' ? 'is-active' : ''}`} onClick={() => layout.setActiveTab('contact')}>
          <Link shallow={true} href="/contact" className="headerMainMenu-link">
            Contact
          </Link>
        </li>
      </ul>

    </StyledHeaderMainMenu>
  );
};

export default HeaderMainMenu;


const StyledHeaderMainMenu = styled.div<{}>`

  display: block;
  font-size: 1rem;

  .headerMainMenu-menu{
    display: flex;
    gap: 1.5rem;
  }

  li{

    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    height: 2.5rem;
    font-weight: 500;
    opacity: 0.8;
    
    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 0%;
      height: 1px;
      padding: 0 0.25rem;
      background-color: white;
      opacity: 0;
      transform: translateX(-50%);
      transition: opacity 0.1s ease-out, width 0.2s ease-out;
    }

    &.is-active, &:hover{

      opacity: 1;

      &::after{
        width: 100%;
        opacity: 1;
      }
    }

  }

  @media (min-width: ${bp(5)}) {

    /* li.headerMainMenu-menu--contact{
      display: none;
    } */


  } 

`;
