import React, { useMemo, useEffect, useRef } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {};

// Component
const HeaderCtaButtons: React.FC<Props> = (props) => {

  const layout = useLayout();

  return (
    <StyledHeaderCtaButtons className="headerCtaButtons">

      {/* Hire Me */}
      <button onClick={() => layout.setActiveTab('contact')}>
        Hire Me
      </button>


      {/* Download My CV */}
      <a href="/files/jon-hobbs-smith-cv.pdf" target="_blank">
        <span>Download My CV</span>
        <span>
          <img src="/images/icons/icon-download-white.svg" alt="Download" />
        </span>
      </a>

    </StyledHeaderCtaButtons>
  );
};

export default HeaderCtaButtons;


const StyledHeaderCtaButtons = styled.div<{}>`

  display: flex;
  gap: 1rem;

  a, button{

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    gap: 0.75rem;
    height: 3rem;
    font-size: 1rem;
    border: 2px solid ${color.blue(60)};
    border-radius: 1.5rem;
    background-color: transparent;
    transition: background-color 0.15s ease-in-out;

    &:hover{
      background-color: ${color.mauve(35)};
    }

    img{
      width: 1.25rem;
      height: 1.25rem;
    }

  }

  @media (min-width: ${bp(3)}) {

    li{

      &.headerCtaButtons-linkedin{
        display: block;
      }

    }

  } 

`;
