import { ReactElement, useEffect , useState } from 'react';
import Head from 'next/head';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { createServerSideHelpers } from '@trpc/react-query/server';
import { DehydratedState } from '@tanstack/react-query';
import { createContext } from 'server/context';

import superJson from 'utils/superJson';
import { appRouter } from 'server/routers/_app';
import getTemplateData from 'utils/getTemplateData';

import MainLayout from 'components/layout/mainLayout';
import HomeSection from 'components/home/homeSection';

import HomeIntro from 'components/home/intro/homeIntro';
import HomeAbout from 'components/home/about/homeAbout';
import HomeSkills from 'components/home/skills/homeSkills';
import HomeQAndA from 'components/home/qAndA/homeQAndA';
import HomeCaseStudy from 'components/home/caseStudies/homeCaseStudy';
import HomeContact from 'components/home/contact/homeContact';

import useLayout from 'providers/layout/layoutProvider';

import theme, { bp, color } from 'settings/theme';


type Props = {
  templateData: TemplateData,
  trpcState: DehydratedState
};

const HomePage: NextPageExtended<Props> = (props) => {

  const layout = useLayout();
  const router = useRouter();

  useEffect(() => {

    if(!router.isReady) return;

    const tabName = router.asPath.replace('/', '');

    layout.setActiveTab(tabName);

  }, [router.isReady]);

  return (
    <>

      <Head>
        <title>Jon HS: Full Stack Developer</title>
      </Head>

      <StyledHomePage className="homePage">
          
        {/* Intro  */}
        <HomeSection className="homePage-intro">
          {({sectionInView}) => (
            <HomeIntro sectionInView={sectionInView} />
          )}
        </HomeSection>

        {/* About  */}
        <HomeSection className="homePage-about">
          {({sectionInView}) => (
            <HomeAbout sectionInView={sectionInView} />
          )}
        </HomeSection>
        
        {/* My Skills */}
        <HomeSection className="homePage-skills">
          {({sectionInView}) => (
            <HomeSkills sectionInView={sectionInView} />
          )}
        </HomeSection>

        {/* Case Studies */}
        <HomeSection className="homePage-case-studies">
          {({sectionInView}) => (
            <div id="homeSection-case-studies">
              <HomeCaseStudy sectionInView={sectionInView} studyName="dssmith" />
              <HomeCaseStudy sectionInView={sectionInView} studyName="beresfords" />
            </div>
          )}
        </HomeSection>

        {/* Q & A */}
        <HomeSection className="homePage-q-and-a">
          {({sectionInView}) => (
            <HomeQAndA sectionInView={sectionInView} />
          )}
        </HomeSection>

        {/* Contact */}
        <HomeSection>
          {({sectionInView}) => (
            <HomeContact sectionInView={sectionInView} />
          )}
        </HomeSection>
        
      </StyledHomePage>

    </>
  );

}

export const getStaticProps = async ({ params }) => {

  const ssg = createServerSideHelpers({
    router: appRouter,
    ctx: await createContext(),
    transformer: superJson
  });


  const results = await Promise.all([
    getTemplateData()
  ]);

  const templateData = results[0];

  return {
    props: {
      templateData,
      trpcState: ssg.dehydrate(),
    },
    revalidate: 1
  };

};

export async function getStaticPaths() {
  return {
    paths: [
      { params: { tab: [''] } },
      { params: { tab: ['about'] } },
      { params: { tab: ['skills'] } },
      { params: { tab: ['case-studies'] } },
      { params: { tab: ['q-and-a'] } },
      { params: { tab: ['contact'] } }
    ],
    fallback: false
  };
}

HomePage.getLayout = (page: ReactElement) => {
  return (
    <MainLayout templateData={page.props.templateData}>
      {page}
    </MainLayout>
  )
}

export default HomePage;

const StyledHomePage = styled.div`

  display: block;

  .homePage-intro{

    .homeSection-inner{
      justify-content: flex-start;
      max-width: 100% !important;
      padding: 0 !important;
    }
    
  }

  .homePage-skills{

    .homeSection-inner{
      justify-content: flex-start;
      max-width: 100% !important;
      padding: 0 !important;
    }

  }

  .homePage-case-studies{

    width: 100%;

    .homeSection-inner{
      justify-content: flex-start;
      max-width: 100% !important;
      padding: 0 !important;
    }

  }

  #homeSection-case-studies{
    width: 100%;
  }

  .homeButton{

    display: flex;
    align-items: center;
    gap: 0.75rem;
    height: 2.5rem;
    padding: 0 1rem;
    border-radius: 1.25rem;
    border: solid 1px white;
    background-color: transparent;
    transition: background-color 0.15s ease-in-out;

    img{
      width: 0.875rem;
      height: 0.875rem;
    }

    &:hover{
      background-color: ${color.mauve(20)};
    }

    &.homeButton--blue{

      border: none;
      background-color: ${color.blue(60)};

      &:hover{
        background-color: ${color.blue(65)};
      }

    }

  }

  @media (min-width: ${bp(4)}) {

    .homeButton{

      gap: 1rem;
      padding: 0 1.5rem;
      font-size: 0.875rem;
      border-radius: 1.5rem;

      img{
        width: 1rem;
        height: 1rem;
      }

    }

  }

`;