import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';


import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean,
  studyName: string
};

// Component
const HomeCaseStudy: React.FC<Props> = (props) => {

  const router = useRouter();
  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('case-studies', {scroll: false, changeRouterState: false})
    }
  }, [props.sectionInView]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.08
      }
    }
  }

  const children = {
    hidden: { y: 40, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.35
      }
    }
  }

  return (
    <StyledHomeCaseStudy sectionInView={props.sectionInView} className="homeCaseStudy">

      {props.studyName === 'dssmith' &&
        <div className="homeCaseStudy-main homeCaseStudy--dssmith">

          <div className="homeCaseStudy-video">
            <video autoPlay loop muted playsInline>
              <source src="/videos/case-studies/cardboard-bw.mp4" type="video/mp4" />
            </video>
          </div>

          <div className="homeCaseStudy-layout">

            <div className="homeCaseStudy-layout-image">
              <div className="homeCaseStudy-image homeCaseStudy-image--1">
                <img src="/images/case-studies/case-study-logo-dssmith.png" alt="DS Smith" />
              </div>
            </div>

            <div className="homeCaseStudy-layout-main">

              <motion.div
                variants={container}
                initial="hidden"
                animate={props.sectionInView ? "show" : "hidden"}
                className="homeCaseStudy-content"
              >
                <motion.div variants={children} className="homeCaseStudy-block homeCaseStudy-image--2">
                  <div className="homeCaseStudy-image">
                    <img src="/images/case-studies/case-study-logo-dssmith.png" alt="DS Smith" />
                  </div>
                </motion.div>
                <motion.div variants={children} className="homeCaseStudy-block homeCaseStudy-title">
                  Case Study: DS Smith
                </motion.div>
                <motion.div variants={children} className="homeCaseStudy-block">
                  <strong>DS Smith</strong> are a FTSE 100 company and Europe&apos;s largest supplier of cardboard packaging.
                </motion.div>
                <motion.div variants={children} className="homeCaseStudy-block homeCaseStudy-block--faded">
                  <p>I lead a team of 5 designers and developers to build internal design tools, including a social design sharing app, used by over 2000 designers in 28 countries.</p>
                </motion.div>
                <motion.div variants={children} className="homeCaseStudy-block homeCaseStudy-block--faded">
                  <p>The app is a single page javascript app with SQL server on the back end and single-sign-on authentication using Microsoft Active Directory.</p>
                </motion.div>
              </motion.div>

            </div> 

          </div>

        </div>
      }

      {props.studyName === 'beresfords' &&
        <div className="homeCaseStudy-main homeCaseStudy--beresfords">
          
          <div className="homeCaseStudy-video">
            <video autoPlay loop muted playsInline>
              <source src="/videos/case-studies/dog-bw.mp4" type="video/mp4" />
            </video>
          </div>

          <div className="homeCaseStudy-layout">

            <div className="homeCaseStudy-layout-image">
              <div className="homeCaseStudy-image homeCaseStudy-image--1">
                <img src="/images/case-studies/case-study-logo-beresfords.png" alt="DS Smith" />
              </div>
            </div>

            <div className="homeCaseStudy-layout-main">

              <motion.div
                variants={container}
                initial="hidden"
                animate={props.sectionInView ? "show" : "hidden"}
                className="homeCaseStudy-content"
              >
                <motion.div variants={children} className="homeCaseStudy-block homeCaseStudy-image--2">
                  <div className="homeCaseStudy-image">
                    <img src="/images/case-studies/case-study-logo-beresfords.png" alt="Beresfords" />
                  </div>
                </motion.div>
                <motion.div variants={children} className="homeCaseStudy-block homeCaseStudy-title">
                  Case Study: Beresfords
                </motion.div>
                <motion.div variants={children} className="homeCaseStudy-block">
                  <strong>Beresfords</strong> are a UK chain of Estate Agents.
                </motion.div>
                <motion.div variants={children} className="homeCaseStudy-block homeCaseStudy-block--faded">
                  <p>I&apos;ve worked on a number of digital projects for Beresfords over the last 10 years, from advertising screens in a network of branches to API integrations with property CRMs.</p>
                </motion.div>
                <motion.div variants={children} className="homeCaseStudy-block homeCaseStudy-block--faded">
                  <p>My Most recent project involved building a customer portal using React, Next JS and Postgres.</p>
                </motion.div>
              </motion.div>

            </div> 
            
          </div>

        </div>
      }

    </StyledHomeCaseStudy>
  );
};

export default HomeCaseStudy;



const StyledHomeCaseStudy = styled.div<{sectionInView: boolean}>`

  display: block;
  width: 100%;

  .homeCaseStudy-main{

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    height: 100svh;
    padding-top: var(--header-height);
    background-color: ${color.mauve(14)};

  }

  .homeCaseStudy-video{

    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.05;
    }

  }

  .homeCaseStudy-layout{

    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10svh;

    .homeCaseStudy-layout-main{
      padding: 0 3rem;
    }

  }

  .homeCaseStudy-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .homeCaseStudy-block{

    font-size: 1.125rem;
    
    &.homeCaseStudy-block--faded {
      p{
        font-size: 1rem;
        opacity: 0.75;
      }
    }

  }

  .homeCaseStudy-image{

    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &.homeCaseStudy-image--1{
      display: none;
    }

  }

  .homeCaseStudy-title{
    font-size: 1.75rem;
    font-weight: 750;
    text-align: center;
  }

  .homeCaseStudy--beresfords{

    .homeCaseStudy-video{

      video{
        object-position: left;
        opacity: 0.125;
      }

    }

  }

  @media (min-width: ${bp(5)}) {

    .homeCaseStudy-layout{

      flex-direction: row;

      .homeCaseStudy-layout-image{


      }

      .homeCaseStudy-layout-main{
        max-width: 60rem;
      }

    }

    .homeCaseStudy-content{
      align-items: flex-start;
    }

    .homeCaseStudy-block{

      font-size: 1.25rem;
      text-align: left;
      
      &.homeCaseStudy-block--faded {
        p{
          font-size: 1.125rem;
        }
      }

    }

    .homeCaseStudy-image{

      width: 14rem;
      height: 14rem;

      &.homeCaseStudy-image--1{
        display: block;
      }

    }

    .homeCaseStudy-image--2{
      display: none;
    }

    .homeCaseStudy-title{
      font-size: 2rem;
    }

  } 


`;
