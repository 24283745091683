import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import Particles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";
import type { ISourceOptions } from 'tsparticles-engine';
import { loadFull  } from "tsparticles";
import dots1Options from "./dots1.json";
import dots2Options from "./dots2.json";
import dots3Options from "./dots3.json";

import useLayout from 'providers/layout/layoutProvider';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeIntro: React.FC<Props> = (props) => {

  const router = useRouter();
  const layout = useLayout();

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('intro', {scroll: false, changeRouterState: false})
    }
  }, [props.sectionInView]);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.08
      }
    }
  }

  const children = {
    hidden: { y: 40, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.35
      }
    }
  }

  // Particles
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <StyledHomeIntro id="homeSection-intro" sectionInView={props.sectionInView} className="homeIntro">

      <div className="homeIntro-main">

        {/* Bubbles */}
        <div className="homeIntro-bubbles">
          <div className="homeIntro-bubbles-layer">
              <Particles
                id="dots1"
                init={particlesInit}
                options={dots1Options as ISourceOptions}
              />
            </div>
            <div className="homeIntro-bubbles-layer">
              <Particles
                id="dots2"
                init={particlesInit}
                options={dots2Options as ISourceOptions}
              />
            </div>
            <div className="homeIntro-bubbles-layer">
              <Particles
                id="dots3"
                init={particlesInit}
                options={dots3Options as ISourceOptions}
              />
            </div>
        </div>

        {/* Background */}
        <div className="homeIntro-main-background"></div>

        {/* Content */}
        <div className="homeIntro-main-content">
          <motion.div
            variants={container}
            initial="hidden"
            animate={props.sectionInView ? "show" : "hidden"}
            className="homeIntro-content"
          >
            <motion.div variants={children} className="homeIntro-section homeIntro-hi">
              Hi, I&apos;m
            </motion.div>
            <motion.div variants={children} className="homeIntro-section homeIntro-name">
              Jon Hobbs-Smith
            </motion.div>
            <motion.div variants={children} className="homeIntro-section homeIntro-text">
              <p>
                I&apos;m a full stack developer whose been passionate about the web since designing my first website for my university radio station over 25 years ago.
              </p>
            </motion.div>
            <motion.div variants={children} className="homeIntro-section homeIntro-buttons">
              <button className="homeButton homeButton-readMore" onClick={() => layout.setActiveTab('about')}>Read More...</button>
              <button className="homeButton homeButton--blue" onClick={() => layout.setActiveTab('contact')}>Hire Me</button>
            </motion.div>
          </motion.div>

        </div>

      </div>

      <div className="homeIntro-next">
        <button className="homeButton homeButton-readMore" onClick={() => layout.setActiveTab('about')}>
          <span>Next: About Me</span>
          <span>
            <img src="/images/icons/icon-arrow-right-white.svg" alt="Next: About Me" />
          </span>
        </button>
      </div>

    </StyledHomeIntro>
  );
};

export default HomeIntro;



const StyledHomeIntro = styled.div<{sectionInView: boolean}>`

  display: block;
  width: 100%;
  padding-top: var(--header-height);

  .homeIntro-main{

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1/1.5;
    padding-left: 40%;
    padding-right: 2.5rem;
    background-color: ${color.mauve(17)};

    .homeIntro-bubbles{

      z-index: 1;
      position: absolute;
      top: 50%;
      left: 25%;
      width: 80%;
      aspect-ratio: 1/1;
      border-radius: 50%;
      translate: -50% -50%;
      opacity: 0.5;

      .homeIntro-bubbles-layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        mask-image: radial-gradient(circle at center, white 0%, transparent 69%);
      }

      @keyframes rotateOasis { 0% { rotate: 120deg; } 100% { rotate: 480deg; } }

    }

    .homeIntro-main-background{
      z-index: 2;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('/images/headshot.png');
      background-size: contain;
      background-repeat: no-repeat;
    }

    .homeIntro-main-content{
      position: relative;
      z-index: 3;
      width: 100%;
    }

  }

  .homeIntro-section{
    display: block;
    font-size: 1.8rem;
    font-weight: 650;
    line-height: 1.3;
  }

  .homeIntro-content{

    .homeIntro-hi{
      font-size: 1.2rem;
      font-weight: 300;
    }

    .homeIntro-name{
      font-size: 1.5rem;
      font-weight: 600;
    }

    .homeIntro-text{

      margin-top: 0.75rem;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.4;
      letter-spacing: 0.0125rem;

      p{
        opacity: 0.75;
      }

    }

    .homeIntro-buttons{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;

      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 300;

      .homeButton-readMore{
        display: none;
      }

    }

  }

  .homeIntro-next{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  @media (min-width: ${bp(2)}) {

    .homeIntro-content{

      .homeIntro-buttons{

        .homeButton-readMore{
          display: block;
        }

      }

    }

    .homeIntro-next{
      display: none;
    }

  }


  @media (min-width: ${bp(3)}) {

    .homeIntro-main{

      aspect-ratio: auto;
      height: 80vh;
      overflow: hidden;
      padding-left: 40%;
      padding-right: 5rem;

      .homeIntro-bubbles{
        left: 10%;
        width: 100%
      }

    }

  } 

  @media (min-width: ${bp(4)}) {

    .homeIntro-main{

      padding-left: 25%;
      padding-right: 10%;

      .homeIntro-bubbles{
        left: -10%;
        top: 60%;
      }

    }

    .homeIntro-content{

      max-width: 50rem;

      .homeIntro-hi{
        font-size: 1.5rem;
      }

      .homeIntro-name{
        font-size: 2rem;
      }

      .homeIntro-text{
        font-size: 1.25rem;
        line-height: 1.4;
        letter-spacing: 0;
      }

      .homeIntro-buttons{
        gap: 1rem;
        margin-top: 1.5rem;
      }

    }

  } 

  @media (min-width: ${bp(5)}) {

    .homeIntro-main{

      .homeIntro-bubbles{
        left: -15%;
      }

    }

  } 

`;
