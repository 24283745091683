import React, { useMemo, useEffect, useRef } from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';

type Props = {};

// Component
const HeaderSocials: React.FC<Props> = (props) => {

  const [hoveredItem, setHoveredItem] = React.useState<string | null>(null);

  // Handle mouse enter
  const handleMouseEnter = (name: string) => {
    setHoveredItem(name);
  };

  // Handle mouse leave
  const handleMouseLeave = (name: string) => {
    setHoveredItem(null);
  };

  return (
    <StyledHeaderSocials className="headerSocials">

      <ul className="headerSocials-menu">
        <li onMouseEnter={() => handleMouseEnter('github')} onMouseLeave={() => handleMouseLeave('github')} className={`headerSocials-github ${hoveredItem && hoveredItem !== 'github' ? 'is-faded' : ''}`}>
          <Link href="https://github.com/JonCognioDigital">
            <img src="/images/icons/icon-github-circle-white.svg" alt="Github" />
          </Link>
        </li>
        {/* <li onMouseEnter={() => handleMouseEnter('medium')} onMouseLeave={() => handleMouseLeave('medium')} className={`headerSocials-medium ${hoveredItem && hoveredItem !== 'medium' ? 'is-faded' : ''}`}>
          <Link href="https://github.com/JonCognioDigital">
            <img src="/images/icons/icon-medium-circle-white.svg" alt="Medium" />
          </Link>
        </li> */}
        <li onMouseEnter={() => handleMouseEnter('linkedin')} onMouseLeave={() => handleMouseLeave('linkedin')} className={`headerSocials-linkedin ${hoveredItem && hoveredItem !== 'linkedin' ? 'is-faded' : ''}`}>
          <Link href="https://www.linkedin.com/in/jon-hobbs-smith">
            <img src="/images/icons/icon-linkedin-circle-white.svg" alt="Linked In" />
          </Link>
        </li>
      </ul>

    </StyledHeaderSocials>
  );
};

export default HeaderSocials;


const StyledHeaderSocials = styled.div<{}>`

  display: block;
  font-size: 1rem;

  .headerSocials-menu{
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  li{

    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &.headerSocials-linkedin{
      display: none;
    }

    &.is-faded{
      opacity: 0.5;
    }

    img{
      width: 2rem;
      height: 2rem;
    }

  }

  @media (min-width: ${bp(3)}) {

    .headerSocials-menu{
      gap: 0.75rem;
    }

    li{

      &.headerSocials-linkedin{
        display: block;
      }

    }

  } 

`;
