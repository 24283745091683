import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import PulseLoader from 'react-spinners/PulseLoader';
import { trpc } from 'utils/trpc';

import useLayout from 'providers/layout/layoutProvider';
import FormInput from 'components/common/forms/formInput';

import theme, {bp, color} from 'settings/theme';

type Props = {
  sectionInView: boolean
};

// Component
const HomeContact: React.FC<Props> = (props) => {

  const layout = useLayout();

  const { register, handleSubmit, reset } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [errors, setErrors] = useState({} as any);

  useEffect(() => {
    if(props.sectionInView){
      layout.setActiveTab('contact', {scroll: false, changeRouterState: false});
      reset();
      setIsComplete(false);
    }
  }, [props.sectionInView]);

  // Animation
  const container = {
    show: {
      transition: {
        staggerChildren: 0.08
      }
    }
  }

  const children = {
    hidden: { y: 40, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.35
      }
    }
  }


  // When form is submitted
  const sendEmailMutation = trpc.email.send.useMutation();

  const onSubmit = async (data: any) => {

    let newErrors: Record<string, string> = {};

    if(data.emailAddress === ''){
      newErrors.emailAddress = 'Please enter your email address';
    }
    
    if(data.message === ''){
      newErrors.message = 'Please enter a message';
    }

    // If the newErrors object has any properties, then there are errors
    setErrors(newErrors);

    if(Object.keys(newErrors).length > 0){
      return;
    }

    const mailData = {
      subject: 'New message from jonhs.dev',
      html: `
        <div>From: ${data.name}</div>
        <div>Company: ${data.company}</div>
        <div>Email: ${data.emailAddress}</div>
        <div>Message: ${data.message}</div>
      `
    };

    try {
      setIsSubmitting(true);
      await sendEmailMutation.mutateAsync(mailData);
      setIsComplete(true);
    }
    catch (error) {
      console.log("error", error);
    }
    finally {
      setIsSubmitting(false);
    }

  };


  return (
    <StyledHomeContact id="homeSection-contact" sectionInView={props.sectionInView} isSubmitting={isSubmitting} className="homeContact">

      <div className="homeContact-inner">

        {!isComplete ? (
          <motion.div
            variants={container}
            initial="hidden"
            animate={props.sectionInView ? "show" : "hidden"}
            className="homeContact-content"
          >

            <motion.div variants={children} className="homeContact-title">
              <h2>Contact Me</h2>
            </motion.div>

            <motion.div variants={children} className="homeContact-intro">
              You can email me at <a href="mailto:hello@jonhs.dev">hello@jonhs.dev</a> or fill in the form below. I&apos;ll get back to you as quickly as I can.
            </motion.div>

            <motion.div variants={children}>

              <form className="homeContact-form" onSubmit={handleSubmit(onSubmit)}>

                <motion.div
                  variants={container}
                  initial="hidden"
                  animate={props.sectionInView ? "show" : "hidden"}
                  className="homeContact-formRows"
                >

                  <motion.div variants={children} className="homeContact-formRow">
                    <FormInput register={register} name="name"  label="Your Name" placeholder="e.g. Robert Jones" isError={!!errors.name} />
                  </motion.div>

                  <motion.div variants={children} className="homeContact-formRow">
                    <FormInput register={register} name="company"  label="Your Company Name" placeholder="e.g. Google" isError={!!errors.company} />
                  </motion.div>

                  <motion.div variants={children} className="homeContact-formRow">
                    <FormInput register={register} name="emailAddress"  label="Your Email Address" placeholder="e.g. robert.jones@google.com" isError={!!errors.emailAddress} />
                  </motion.div>

                  <motion.div variants={children} className="homeContact-formRow">
                    <FormInput register={register} inputType="textarea" name="message"  label="Your Message" isError={!!errors.message} />
                  </motion.div>

                  <motion.div variants={children} className="homeContact-formRow">
                    <button type="submit" className="homeContact-submitButton">
                      {isSubmitting ? (
                        <><PulseLoader size={6} color="white" speedMultiplier={0.8} /></>
                      ) : (
                        <>Send Message</>
                      )}
                    </button>
                  </motion.div>

                </motion.div>

              </form>

            </motion.div>
          </motion.div>
        ) : (
          <>
          
          <div className="homeContact-title">
            <h2>Message Received</h2>
          </div>

          <div className="homeContact-intro">
            <div>Thanks for getting in touch! I&apos;ll get back to you as soon as I can</div>
          </div>
          
          </>
        )}

      </div>      

    </StyledHomeContact>
  );

};

export default HomeContact;



const StyledHomeContact = styled.div<{sectionInView: boolean, isSubmitting: boolean}>`

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: calc(var(--header-height) + 2rem);
  padding-bottom: calc(var(--header-height) + 2rem);
  min-height: 100vh;
  min-height: 100svh;

  .homeContact-title{

    margin-bottom: 0.75rem;

    h2{
      font-size: 2rem;
      font-weight: 300;
    }

  }

  .homeContact-intro{

    margin-bottom: 2rem;
    color: ${color.mauve(90)};

    a{
      color: ${color.mauve(100)};
      font-weight: 500;
      text-decoration: underline;
    }

  }

  .homeContact-form{
    width: 100%;
    max-width: 40rem;
  }

  .homeContact-formRows{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }

  .homeContact-submitButton{

    width: 100%;
    height: 3rem;
    padding: 0 1rem;
    font-size: 0.925rem;
    font-weight: 600;
    text-align: center;
    border-radius: 0.5rem;
    background-color: ${props => props.isSubmitting ? color.mauve(40) : color.blue(60)};
    transition: background-color 0.15s ease-in-out;
    pointer-events: ${props => props.isSubmitting ? 'none' : 'all'};

    &:hover{
      background-color: ${color.blue(65)};
    }

  }


  @media (min-width: ${bp(3)}) {

    .homeContact-submitButton{
      font-size: 1rem;
    }

  } 

  @media (min-width: ${bp(5)}) {

    .homeContact-title{

      margin-bottom: 1.5rem;

      h2{
        font-size: 2.5rem;
        font-weight: 400;
      }

    }

    .homeContact-intro{
      font-size: 1.125rem;
      line-height: 1.4;
      letter-spacing: 0;
    }

  }

`;
