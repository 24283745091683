import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';

type Props = {
  className?: string,
  children(passProps: { 
    sectionInView: boolean
  }): React.ReactNode
};

// Component
const HomeSection: React.FC<Props> = (props) => {

  const { ref, inView, entry } = useInView({
    threshold: 0.4
  });

  return (
    <StyledHomeSection ref={ref} inView={inView} className={`homeSection ${props.className ?? ''}`}>
      <div className="homeSection-inner">
        {props.children({ sectionInView: inView })}
      </div>
    </StyledHomeSection>
  );
};

export default HomeSection;



const StyledHomeSection = styled.div<{inView: boolean, fullWidth: boolean} & Props>`

  position: relative;
  width: 100%;

  .homeSection-inner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 2rem 1.5rem 5rem 1.5rem;
    min-height: calc(100vh - var(--header-height));
    min-height: calc(100svh - var(--header-height));
  }


  @media (min-width: ${bp(2)}) {

    .homeSection-inner{
      padding: 2rem 2.5rem 5rem 2.5rem;
    }

  }

  @media (min-width: ${bp(3)}) {

    .homeSection-inner{
      padding: 2rem 4rem 5rem 4rem;
    }

  }

  @media (min-width: ${bp(4)}) {

    .homeSection-inner{
      max-width: 60rem;
      margin: 0 auto;
      padding: 2rem 0 10rem 0;
    }

  }

  @media (min-width: ${bp(5)}) {

    .homeSection-inner{
      max-width: 75rem;
    }

  }

`;
