import React, { useState, useMemo, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import useLayout from 'providers/layout/layoutProvider';
import useClickOutside from 'hooks/useClickOutside';

import theme, {bp, color} from 'settings/theme';

type Props = {};

const convertTabToTitle = (tab) => {
  switch(tab){
    case 'intro':
      return 'Intro';
    case 'about':
      return 'About Me';
    case 'skills':
      return 'My Skills';
    case 'case-studies':
      return 'Case Studies';
    case 'q-and-a':
      return 'Q & A';
    case 'contact':
      return 'Contact';
  }
}

// Component
const HeaderMobMenu: React.FC<Props> = (props) => {

  const layout = useLayout();
  const router = useRouter();
  const ref = useRef();

  // Calculate Active Tab
  const activeTab = useMemo(() => {

    const homePageTabs = ['/', '/about', '/skills', '/case-studies', '/q-and-a', '/contact'];

    if(homePageTabs.includes(router.asPath)){
      return layout.activeTab === '/' ? 'intro' : layout.activeTab;
    }

  }, [layout.activeTab, router.asPath]);

  // State
  const [isOpen, setIsOpen] = useState(false);

  // Handle button click
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  // When the user clicks outside
  useClickOutside(ref, () => {
    setIsOpen(false);
  }, 'headerMobMenu-button');

  // Handle menu item click
  const handleMenuItemClick = (tab) => {
    layout.setActiveTab(tab);
    setIsOpen(false);
  };

  return (
    <StyledHeaderMobMenu isOpen={isOpen} className="headerMobMenu" ref={ref}>

      <div className="headerMobMenu-inner">

        <button className="headerMobMenu-button" onClick={handleButtonClick}>
          <span>{convertTabToTitle(activeTab)}</span>
          <span>
            <img src="/images/icons/icon-chevron-down-white.svg" alt="Open menu" />
          </span>
        </button>

        <ul className="headerMobMenu-menu">
          <li className={activeTab === 'intro' ? 'is-active' : ''} onClick={() => handleMenuItemClick('intro')}>
            <Link shallow={true} href="/" className="headerMainMenu-link">
              Intro
            </Link>
          </li>
          <li className={activeTab === 'about' ? 'is-active' : ''} onClick={() => handleMenuItemClick('about')}>
            <Link shallow={true} href="/about" className="headerMainMenu-link">
              About Me
            </Link>
          </li>
          <li className={activeTab === 'skills' ? 'is-active' : ''} onClick={() => handleMenuItemClick('skills')}>
            <Link shallow={true} href="/skills" className="headerMainMenu-link">
              My Skills
            </Link>
          </li>
          <li className={activeTab === 'case-studies' ? 'is-active' : ''} onClick={() => handleMenuItemClick('case-studies')}>
            <Link shallow={true} href="/case-studies" className="headerMainMenu-link">
              Case Studies
            </Link>
          </li>
          <li className={activeTab === 'q-and-a' ? 'is-active' : ''} onClick={() => handleMenuItemClick('q-and-a')}>
            <Link shallow={true} href="/q-and-a" className="headerMainMenu-link">
              Q &amp; A
            </Link>
          </li>
          <li className={`headerMainMenu-menu--contact ${activeTab === 'contact' ? 'is-active' : ''}`} onClick={() => handleMenuItemClick('contact')}>
            <Link shallow={true} href="/contact" className="headerMainMenu-link">
              Contact
            </Link>
          </li>
        </ul>
        
      </div>

    </StyledHeaderMobMenu>
  );
};

export default HeaderMobMenu;


const StyledHeaderMobMenu = styled.div<{isOpen: boolean}>`

  --mobMenuHeight: 2.5rem;

  display: block;

  .headerMobMenu-inner{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: var(--mobMenuHeight);
  }

  .headerMobMenu-button{

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    height: var(--mobMenuHeight);
    padding: 1rem;
    font-size: 0.825rem;
    border-radius: 1.25rem;
    background-color: ${props => props.isOpen ? color.mauve(30) : 'transparent'};
    transition: background-color 0.15s ease-in-out;

    &:hover{
      background-color: ${color.mauve(30)}
    }

    img{
      width: 0.875rem;
      height: 0.875rem;
      vertical-align: middle;
      transform: translateY(-0.1rem);
    }

  }

  .headerMobMenu-menu{

    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 1.25rem;
    background-color: ${color.mauve(20)};

    opacity: ${props => props.isOpen ? 1 : 0};
    transform: ${props => props.isOpen ? 'translateY(0.5rem)' : 'translateY(1rem)'};
    pointer-events: ${props => props.isOpen ? 'auto' : 'none'};
    transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;

    li{
      
      a{

        display: flex;
        align-items: center;
        height: 2.25rem;
        padding: 0 1rem 0 1rem;
        font-size: 0.875rem;
        background-color: transparent;

        &:hover{
          background-color: ${color.mauve(15)}
        }

      }
    }

  }


  @media (min-width: ${bp(2)}) {

    .headerMobMenu-button{
      font-size: 0.875rem;
    }

    .headerMobMenu-menu{

      li{
        a{
          padding: 0 3rem 0 1rem;
        }
      }

    }

  }


  @media (min-width: ${bp(3)}) {

  } 

`;
