import React, { useState, useEffect, useRef } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';

type Props = {
  inView?: boolean,
  rating: number,
  strokeColor: string,
  delay?: number
};

// Component
const Progress: React.FC<Props> = (props) => {

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const radius = containerWidth / 2;
  const delay = (props.delay ?? 0) + 0.05;

  const circumference = Math.ceil(2 * Math.PI * radius);
  const fillPercents = Math.abs(
    Math.ceil((circumference / 100) * ((props.rating ?? 0) - 100))
  );

  const transition = {
    duration: 0.5,
    delay: delay,
    ease: "easeInOut"
  };

  const variants = {
    hidden: {
      strokeDashoffset: circumference,
      transition
    },
    show: {
      strokeDashoffset: fillPercents,
      transition
    }
  };

  useEffect(() => {

    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  return (

    <div ref={containerRef} style={{width: "100%", height: "100%"}}>
      {!!containerWidth &&
        <>
          <svg
              viewBox="0 0 100% 100%"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: "100%",
                height: "100%"
              }}
            >
              <circle
                cx="50%"
                cy="50%"
                r={radius}
                className="circle"
                strokeWidth={1}
                stroke={props.strokeColor}
                strokeOpacity={0.25}
                fill="transparent"
              />
            </svg>
            <svg
              viewBox="0 0 50% 50%"
              preserveAspectRatio="none"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                transform: "rotate(-90deg)",
                overflow: "visible"
              }}
            >
              <motion.circle
                cx="50%"
                cy="50%"
                r={radius}
                strokeWidth={3}
                stroke={props.strokeColor}
                fill="transparent"
                strokeDashoffset={fillPercents}
                strokeDasharray={circumference}
                variants={variants}
                initial="hidden"
                animate={props.inView ? "show" : "hidden"}
              />
            </svg>
        </>
      }
    </div>


    
  );

};

export default Progress;
